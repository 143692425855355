var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.isCheck
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailInfo",
                  attrs: {
                    title: "설비 기본정보",
                    topClass: "topcolor-orange",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _c("c-btn", {
                            attrs: {
                              label: "기기정비 이력기록서",
                              icon: "assignment",
                            },
                            on: { btnClicked: _vm.btnRecord },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검부서",
                            value: _vm.selectedRow.deptNm,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설비유형",
                            value: _vm.selectedRow.equipmentType,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설비코드",
                            value: _vm.selectedRow.equipmentCd,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설비명",
                            value: _vm.selectedRow.equipmentNm,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비유형",
                            value: _vm.selectedRow.safCheckTypeNm,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "이전점검일",
                            value: _vm.selectedRow.befInspYear,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검주기",
                            value: _vm.selectedRow.inspCycle,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "차기법정예정월",
                            value: _vm.selectedRow.nextInspMon,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "실제점검자",
                            value: _vm.selectedRow.chkUserNm,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검완료일",
                            value: _vm.selectedRow.chkSchCompleYmd,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검결과",
                            value: _vm.selectedRow.chkResult,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "관련법규",
                            value: _vm.selectedRow.relatedLaws,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-9" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검결과 요약",
                            value: _vm.selectedRow.chkRsltSmry,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isCheck
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              attrs: { topClass: "topcolor-orange" },
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "설비별 점검항목",
                    columns: _vm.grid2.columns,
                    gridHeight: _vm.grid2.height,
                    data: _vm.grid2.data,
                    filtering: false,
                    columnSetting: false,
                    usePaging: false,
                    hideBottom: true,
                    noDataLabel: "설비에 대한 점검항목이 없습니다.",
                    rowKey: "checkItemSeq",
                    cardClass: "topcolor-orange",
                  },
                },
                [_c("template", { slot: "table-button" })],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "설비 기본정보", topClass: "topcolor-orange" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        attrs: {
                          label: "기기정비 이력기록서",
                          icon: "assignment",
                        },
                        on: { btnClicked: _vm.btnRecord },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "정비부서",
                        value: _vm.selectedRow.deptNm,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "설비유형",
                        value: _vm.selectedRow.equipmentType,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "설비코드",
                        value: _vm.selectedRow.equipmentCd,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "설비명",
                        value: _vm.selectedRow.equipmentNm,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "정비유형",
                        value: _vm.selectedRow.safCheckTypeNm,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "정비부서 입회자",
                        value: _vm.selectedRow.chkUserNm,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "이전정비일",
                        value: _vm.selectedRow.befInspYear,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "실제정비자",
                        value: _vm.selectedRow.chkUserNm,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "정비완료일",
                        value: _vm.selectedRow.chkSchCompleYmd,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "정비주기",
                        value: _vm.selectedRow.inspCycle,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "차기법정예정월",
                        value: _vm.selectedRow.nextInspMon,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-3" },
                  [
                    _c("c-label-text", {
                      attrs: {
                        title: "관련법규",
                        value: _vm.selectedRow.relatedLaws,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-12" }),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.isCheck
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: {
                    title: "설비 정비결과",
                    topClass: "topcolor-orange",
                    bgClass: "",
                  },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비후 점검결과",
                            value: _vm.selectedRow.chkResult,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비시간",
                            value: _vm.selectedRow.chkMaintenanceYmd,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "운전정지시간",
                            value: _vm.selectedRow.chkStopYmd,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비자 자격사항",
                            value: _vm.selectedRow.chkUserQualify,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비원인",
                            value: _vm.selectedRow.chkRsltCause,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비내용",
                            value: _vm.selectedRow.chkRsltContent,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "정비결과요약",
                            value: _vm.selectedRow.chkRsltSmry,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "관리자의 검토의견",
                            value: _vm.selectedRow.managerOpinion,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }